<template>
    <div>
        <v-layout row wrap :align-center="!wideLayout" :justify-center="!wideLayout" :fill-height="!wideLayout">
            <v-flex xs12 sm12 :md12="wideLayout" :lg12="wideLayout" :md10="!wideLayout" :lg10="!wideLayout" :xl8="!wideLayout">
                

                <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageOptions: [5,10,15,25,50, 100]
                }"
                >
                <template v-slot:top>
                    <v-toolbar class="elevation-1" color="white">
                        <v-toolbar-title class="font-weight-regular body-1">{{ settings.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-flex xs4 md4 lg4 v-show="breakPoint">
                            <!--<v-text-field class="pt-4"  label="Išči..." @keyup.esc="search = ''" v-model="search" append-icon="mdi-magnify" single-line></v-text-field>-->
                            <v-text-field class="pt-4"  label="Išči..." @keyup.esc="search = ''" @keyup="doSearch()" v-model="search" append-icon="mdi-magnify" single-line clearable @click:clear="doSearch()"></v-text-field>
                        </v-flex>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-tooltip v-if="btnRefresh" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="refreshDataTable" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Osveži podatke</span>
                        </v-tooltip>
                        <v-tooltip v-if="btnToggleColumns" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleColumns()" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <span>Filtriraj stolpce</span>
                        </v-tooltip>
                    </v-toolbar>
                    <br>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip top v-if="item.application.id != '' && item.application.uuid != ''">
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_edit"
                                color="#546E7A"
                                small
                                class="mr-2"
                                @click="viewInvoiceUrl(item)"
                                v-on="on"
                            >
                                mdi-receipt
                            </v-icon>
                            <!--<a :href="viewInvoiceUrl(item)" style="text-decoration:none;">
                                <v-icon
                                id="dt_btn_edit"
                                color="success"
                                small
                                class="mr-2"
                                v-on="on"
                                >
                                mdi-receipt
                                </v-icon>
                            </a>-->
                        </template>
                        <span>Račun</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.application.id != '' && item.application.uuid != ''">
                        <template v-slot:activator="{ on }">
                            <a :href="editApplicationUrl(item)" style="text-decoration:none;">
                                <v-icon
                                id="dt_btn_edit"
                                color="success"
                                small
                                class="mr-2"
                                v-on="on"
                                >
                                mdi mdi-file-document-edit-outline
                                </v-icon>
                            </a>
                        </template>
                        <span>Uredi vlogo</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.paid_application.amount="{ item }">
                    <v-chip label v-if="item.paid_application.amount !== null && item.paid_application.amount !=='null' && item.imported !== ''" :color="getPriceColour(item)" dark small>{{ item.paid_application.amount }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.application.digital="{ item }">
                    <v-chip label v-if="item.application.digital !== null && item.application.digital !=='null' && item.application.digital !== ''" :color="getApplicationMediaTypeColor(item)" dark small>{{ getApplicationMediaTypeName(item) }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.application_id="{ item }">
                    <v-chip label v-if="item.application_id !== null && item.application_id !=='null' && item.imported !== ''" :color="getApplicationIdColor(item)" dark small>{{ item.application_id }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:item.paid_application.reference="{ item }">
                    <v-chip label v-if="item.paid_application.reference !== null && item.paid_application.reference !=='null' && item.imported !== ''" :color="getPaidApplicationColor(item)" dark small>{{ item.paid_application.reference }}</v-chip>
                    <span v-else></span>
                </template>

                <template v-slot:progress>
                    <v-progress-linear absolute  indeterminate :height="progressHeight()"></v-progress-linear>
                </template>
                <template slot="no-data">
                    <div class="no_data" v-show="!searchQuery">{{ (this.loading) ? "Obdelava podatkov je v teku ..." : "Ni podatkov za prikaz." }}</div>
                    <div class="no_data" v-show="searchQuery">{{ (this.loading) ? 'Obdelava podatkov za iskani niz "'  + this.search + '" je v teku ...' :  `Ni podatkov za iskani niz »${this.search}«.`}}</div>
                </template>
                <template slot="loading">
                    <div class="no_data">Obdelava podatkov je v teku ...</div>
                </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import { post, get, put, destroy, API_ENDPOINT, DEMO_API_ENDPOINT } from '@/helpers/api'
import { EventBus } from '@/main.js'
//const PayedApplicationsDataTable = () => import('@/components/PayedApplicationsDataTable.vue')

export default {
    props: ['settings', 'queryParameters', 'boxedLayout'],
    name: 'invoice-data-table',

    components: {
        //PayedApplicationsDataTable
    },

    data: () => ({
        search: '',
        totalItems: 0,
        items: [],
        loading: true,
        options: {},
        rowsPerPageItems: [5,10,15,25,50,100, -1], 
        interval: null,
        dialog: false,
        dialog_text: '',
        itemToDelete: null,
        applicationsListDialog: false,
        sendInvoicesSelectedData: null,
        paymentUuid: null,
        searchTimer:null
    }),

    watch: {
        options: {
            handler () {
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })

                //window.console.log(this.options)
                //window.console.log(this.params)
            },
            deep: true,
        },

        /*search(newValue) {
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
        }*/

        searchFromStore(newValue) {
            this.search = newValue
            this.doSearch()
        }
    },

    computed: {
        params(nv) {
            return {
                ...this.options,
                query: this.search
            }
        },

        searchQuery() {
           return (this.search != null && this.search.trim() != '') ? true : false;
       },

       btnRefresh() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.refresh) {
               return true
           }

           return false
       },

       btnExport() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.export) {
               return true
           }

           return false
       },

       btnAddNew() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.addNew) {
               return true
           }

           return false
       },

       btnToggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != ''
           ) {
               return true
           }

           return false
       },

       breakPoint() {
           if(this.$vuetify.breakpoint.mdAndUp) {
                return true;
            }

             return false;
       },

       apiParams() {
           let params = {
                ...this.options,
                query: this.search,
                columns: this.settings.headers.map(header => {
                    return header.value
                })
            }

            if(this.queryParameters && this.queryParameters != null) {
                let queryParams = this.queryParameters
                params = {
                    ...params,
                    ...queryParams
                }
            }

            //window.console.log("queryParams")
            //window.console.log(params)

           return params;
       },

       headers() {
           return this.settings.headers.filter(header => {
               if(header.visibility) {
                   return header
               }
           });
       },

       searchFromStore: {
           get() {
               return this.$store.getters.search
           },

           set(value) {
               this.$store.commit('SET_SEARCH', this.search)
           }
       },

       wideLayout() {
           if(this.boxedLayout !== undefined) {
               if(this.boxedLayout === true) {
                   return false
               }

               return true
           }

           return true
       },

       computePaymentUuid() {
           return this.paymentUuid
       }
    },

    methods: {
        getDataFromApi () {
            this.loading = true
            
            if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                if(this.settings.eventBus.totalItems) {
                    EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: true, value: 0})
                }
            }

            if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('sumItems')) {
                if(this.settings.eventBus.sumItems) {
                    EventBus.$emit(this.settings.eventBus.sumItems.event, {progress: true, queryParameters: this.apiParams})
                }
            }

            return new Promise((resolve, reject) => {
                //post(API_ENDPOINT + this.settings.endpoint, this.apiParams)
                post(API_ENDPOINT + this.settings.endpoint, this.apiParams)            
                .then(response => {
                    //window.console.log("API data")
                    //window.console.log(response.data)
                    const {
                       sortBy,
                       sortDesc, 
                       page,
                       itemsPerPage 
                    } = this.options;

                    //this.pagination.rowsPerPage = response.data.meta.per_page
                    //console.log(response)
                    const items = response.data.data
                    const totalItems = response.data.meta.total

                    if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: totalItems})
                        }
                    }

                    resolve({items, totalItems})
                })
                .catch(error => {
                    if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: 0})
                        }
                    }
                    reject(error)
                })
                .finally(() => {
                    this.loading = false
                    if(this.settings.hasOwnProperty('eventBus') && this.settings.eventBus.hasOwnProperty('totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: false})
                        }
                    }
                })
            })
      },

       addNewItem() {
           if(this.settings.toolbar.buttons.addNewRoute != '' && this.settings.toolbar.buttons.addNewRoute != null) {
               this.$router.push({name: this.settings.toolbar.buttons.addNewRoute})
           }
       },

       refreshDataTable() {
           this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
       },

       toggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != '') {
                    EventBus.$emit(this.settings.eventBus.toggleColumnsId, {
                        toggleColumnsMenu: true
                    });
            }
       },

       toggleDataTableColumns(data) {
           if(Object.prototype.hasOwnProperty.call(data, 'dataTableHeaders') ) {
               //window.console.log(data)
                for(var i=0; i<this.settings.headers.length; i++) {
                    var headerFound = false;
                    for(var k=0; k<data.dataTableHeaders.length; k++) {
                        if(this.settings.headers[i].value == data.dataTableHeaders[k]) {
                            this.settings.headers[i].visibility = true
                            headerFound = true
                        }
                    }

                    if(headerFound == false) {
                        //console.log(this.dataTableSettings.headers[i])
                        this.settings.headers[i].visibility = false
                    }
                }

           }
       },

       progressHeight() {
           if(this.settings.hasOwnProperty('progressHeight') && this.settings.progressHeight != null && this.settings.progressHeight != '') {
               return this.settings.progressHeight
           }

           return 3
       },

       getTotalPaidApplicationsColor() {

           return 'light-blue darken-3'
       },

       getTotalUnpaidApplicationsColor() {
           return "#e91e63";
       },

       getImportedColor(item) {
           if(item == 1) {
               return 'success'
           }

            return "warning"
       },

       getApplicationIdColor(item) {
           //window.console.log(item)
           if(item.permit_type != null) {
               if(item.permit_type.type == 1) {
                   return "#2196F3"
               }

               if(item.permit_type.type == 1) {
                   return "#3F51B5"
               }
           }

           return "info"
       },

       getPaidApplicationColor(item) {
           return '#546E7A'
       },

       //barva za znesek
       getPriceColour(item) {
           return "warning";
       },

       getApplicationMediaTypeColor(item) {
           if(item.application != null) {
               if(item.application.digital == 1) {
                   return '#26c6da'
               } else {
                   return '#8d6e63'
               }
           }

           return "info"
       },

       getApplicationMediaTypeName(item) {
           if(item.application != null) {
               if(item.application.digital == 1) {
                   return 'E-vloga'
               } else {
                   return 'Vloga'
               }
           }

           return "X"
       },

       editApplicationUrl(item) {
           let itemLocation = ""
           let location = ""

            if(item.application.digital == 1) {
                location = this.$router.resolve({name: 'admin_applications_edit', params: {id:item.application.uuid}})
            } else {
                location = this.$router.resolve({name: 'admin_applications_edit2', params: {id:item.application.uuid}})
            }
            
            itemLocation = window.location.origin + location.href;

            return itemLocation
       },

       viewInvoiceUrl(item) {
           let downloadUrl = API_ENDPOINT + 'v1/files/applications/parking/' + item.application.id + '/' + item.application.uuid + '/invoice/view'
           Object.assign(document.createElement('a'), {target: '_blank',href: downloadUrl}).click();
       },
    
        doSearch() {
           let vm = this
           if(this.searchTimer) {
               clearTimeout(this.searchTimer)
               this.searchTimer = null
           }

           this.searchTimer = setTimeout(() => {
               vm.getDataFromApi()
                .then(data => {
                   vm.items = data.items
                   vm.totalItems = data.totalItems
               })
               .catch(error => {
                   vm.items = [];
                   vm.totalItems = 0;
                   window.console.log(error)
               })
           }, 600)
       }
       
    },

    created() {
        /*window.console.log("DataTableSettings");
        window.console.log(this.settings)

        window.console.log("Query Parameters")
        window.console.log(this.queryParameters)*/
    },

    mounted() {
        if(this.settings && this.settings.sortColumn) {
            this.options.sortBy = this.settings.sortColumn
        }

        if(this.settings && this.settings.sortDirection && this.settings.sortDirection == 'desc') {
            this.options.sortDesc = this.settings.sortDirection
        }

        if(this.settings && this.settings.hasOwnProperty('eventBus')) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$on(this.settings.eventBus.refreshDataTable, (data) => {
                    this.refreshDataTable()
                })
            }

            if(this.settings.eventBus.search != null && this.settings.eventBus.search.trim() != '') {
                EventBus.$on(this.settings.eventBus.search, (data) => {
                    this.search = data
                })
            }

            if(this.settings.id != null && this.settings.id !== undefined) {
                EventBus.$on(this.settings.id, (data) => {
                    this.toggleDataTableColumns(data)
                })
            }
        }

        if(this.settings && this.settings.updateInterval) {
            this.interval = setInterval(() => {
                if(this.loading != true) {
                    this.getDataFromApi()
                    .then(data => {
                        this.items = data.items
                        this.totalItems = data.totalItems
                    })
                    .catch(error => {
                        this.items = [];
                        this.totalItems = 0;
                        window.console.log(error)
                    })
                }
            }, process.env.VUE_APP_DATA_TABLES_REFRESH_INTERVAL || 180000)
       }

       if(this.settings && this.settings.rowsPerPage) {
           this.options.itemsPerPage = this.settings.rowsPerPage
       }

       //window.console.log("Boxed Layout: " + this.boxedLayout)
    },

    beforeDestroy() {

    },

    destroyed() {
        clearInterval(this.interval)
        if(this.settings && this.settings.eventBus  && this.settings.eventBus.refreshDataTable) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$off(this.settings.eventBus.refreshDataTable)
            }
        } 

        EventBus.$off(this.settings.id)
        this.sendInvoicesSelectedData = null
    }
}

</script>

<style scoped>
    .no_data {
        text-align:center !important;
    }

    #style-11::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    
    #style-11::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
        border: 1px solid #ccc;
    }

    #style-11::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(left, #fff, #e4e4e4);
        border: 1px solid #aaa;
    }

    #style-11::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }

    #style-11::-webkit-scrollbar-thumb:active {
        background: linear-gradient(left, #22ADD4, #1E98BA);
    }

    .toolbar_btn_fab {
        margin-right:10px;
    }

    #dt_btn_account {
        font-size:18px !important;
    }

    #dt_btn_edit {
        font-size:18px !important;
    }

    #dt_btn_delete {
        font-size:18px !important;
    }

    .no_data {
        color: #000 !important;
    }

    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }


</style>